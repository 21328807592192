<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-snackbar v-model="snackbar" timeout="2000" top color="amber">
        Имэйлүүдийг xуулсан:
        <h1>({{ snackbarText }})</h1>
        <template v-slot:action="{ attrs }">
          <v-btn
            class="font-weight-bold"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Xааx
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="border-radius-xl card-shadow">
        <v-card-title class="px-10">
          <v-row>
            <v-col>
              <h2>Цэцэрлэг</h2>
              <v-btn  @click="_callTeacher">dfsdf</v-btn>
            </v-col>
            <v-col class="text-end">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6"
                @click="_addNewSchool"
                >Цэцэрлэг+</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <!-- 
        <p>{{ this.gClassGroups.map((dd) => dd.academic_level).join(",") }}</p> -->
        <v-progress-linear
          v-if="loading"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-data-table
          :headers="headers"
          v-if="filteredSchools && filteredSchools.length > 0"
          :items="filteredSchools"
          :items-per-page="500"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td @click="_print(props.item)">{{ props.item.index }}</td>
              <td style="cursor: pointer">
                <span> {{ props.item.name }}</span>
              </td>
              <td>
                {{ props.item.academic_level }}
              </td>
              <td>{{ props.item.academic_year }}</td>

              <td>{{ props.item.program_of_study_id }}</td>
              <td>{{ props.item.program_stage_id }}</td>
              <td>{{ props.item.student_group_category }}</td>
              <td>{{ props.item.student_group_id }}</td>
              <td>
                <v-btn @click="_retrieveStudents(props.item)"
                  >{{ props.item.student_group_id }}+</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-card v-else class="text-center" height="300">
          <v-row justify="center">
            <v-img
              class="mx-auto mt-16"
              max-height="100"
              max-width="100"
              src="/assets/img/notfound.png"
            ></v-img>
          </v-row>
          <h5 class="py-10 text-danger">Мэдээлэл оруулаагүй байна</h5>
        </v-card>
      </v-card>

      <div class="py-3" />
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
import axios from "axios";
export default {
  name: "RegularTablesView",
  data: () => ({
    loading: false,
    snackbarText: null,
    snackbar: false,
    gClassGroups: null,
  }),
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),
    filteredSchools() {
      return this.gClassGroups;
    },
    headers() {
      return [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
          width: "2%",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
          width: "20%",
        },
      ];
    },
  },
  async created() {
    console.log(this.userData.ref.path);
    // this._retrieveClassGroups(43944);
  },
  methods: {
    async _callTeacher(){
      // .get("https://dep.edu.mn/api/contracted-orgs/instructors"
    
      await axios
      .get("https://dep.edu.mn/api/contracted-orgs/employees", {
          params: {
            business_group_id: 39870,
            page_size: 1000,
          },
          headers: {
            ClientId: this.$store.state.middleSystemInfo.ClientId,
            ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
          },
        }).then((response) => {
          console.log(response)
        })
    },
    _addNewSchool() {
      this.$swal
        .fire({
          title: "Цэцэрлэгийг автоматаар үүсгэx үү?",
          text: "Шууд өдрийн ирц бүртгэx бүрэн боломжтойгоор анги, бүлэг, суралцагчийн мэдээллийг татаж цэцэрлэг, сургуулийг автоматаар үүсгэнэ!",
          input: "number",
          inputPlaceholder: "Байгууллагын кодыг оруулна уу!",
          // inputAttributes: {
          //   min: 1, // Set minimum value
          //   max: 10000, // Set maximum value
          //   step: 1, // Define the step interval for number input
          // },

          showCancelButton: true,
          customClass: {
            title: "modal-title",
            input: "centered-input",
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            console.log("Entered number:");
            this._retrieveClassGroups(result.value);
          }
        });
    },
    async _retrieveClassGroups(esis_id) {
      this.loading = true;
      await axios
        .get("https://dep.edu.mn/api/contracted-orgs/groups", {
          params: {
            esis_id: esis_id,
            page_size: 1000,
          },
          headers: {
            ClientId: this.$store.state.middleSystemInfo.ClientId,
            ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
          },
        })
        .then(async (response) => {
          console.log("Response:", response.data);
          fb.db
            .collection("schools")
            .doc(String(esis_id))
            .set(
              {
                isKinderGarten: true,
                name: String(esis_id),
                currentYear: 2024,
                createdAt: new Date(),
                deleted: false,
                _esisContracted: true,
                _esis_schoolInfo: {
                  academicYear: 2024,
                  legalEntityId: esis_id,
                  organizationName: String(esis_id),
                },
              },
              { merge: true }
            );
          if (response.data && response.data.items) {
            var batch = fb.db.batch();
            this.gClassGroups = response.data.items;
            if (this.gClassGroups) {
              for (const cg of this.gClassGroups) {
                console.log(cg);
              }
            }
            var deps = [
              ...new Set(this.gClassGroups.map((dd) => dd.academic_level)),
            ].sort((a, b) => a - b);

            for (const dp of deps) {
              var depDocRef = fb.db
                .collection("schools")
                .doc(String(esis_id))
                .collection("departments-2024")
                .doc("department-" + String(dp));
              batch.set(
                depDocRef,
                {
                  createdAt: new Date(),
                  deleted: false,
                  index: dp,
                  name: String(dp),
                  startYear: 2024,
                },
                { merge: true }
              );
            }
            for (const cg of this.gClassGroups) {
              console.log(cg, "cg");

              let upperCaseKeysCLASS_GROUP = Object.keys(cg).reduce(
                (result, key) => {
                  result[key.toUpperCase()] = cg[key];
                  return result;
                },
                {}
              );

              console.log(
                upperCaseKeysCLASS_GROUP,
                "upperCaseKeysCLASS_GROUP",
                cg
              );

              var programDocRef = fb.db
                .collection("schools")
                .doc(String(esis_id))
                .collection("departments-2024")
                .doc(
                  "department-" +
                    String(upperCaseKeysCLASS_GROUP.ACADEMIC_LEVEL)
                )
                .collection("programs")
                .doc(String(upperCaseKeysCLASS_GROUP.STUDENT_GROUP_ID));

              upperCaseKeysCLASS_GROUP.createdAt = new Date();
              upperCaseKeysCLASS_GROUP.name = cg.name;
              upperCaseKeysCLASS_GROUP.departmentName = String(
                cg.academic_level
              );
              upperCaseKeysCLASS_GROUP.deleted = false;
              upperCaseKeysCLASS_GROUP.startYear = 2024;
              upperCaseKeysCLASS_GROUP.fullName = cg.name;
              upperCaseKeysCLASS_GROUP.STUDENT_GROUP_NAME = cg.name;

              batch.set(programDocRef, upperCaseKeysCLASS_GROUP, {
                merge: true,
              });

              //students
              var students = await this._retrieveClassGroupStudents(
                cg.student_group_id
              );
              for (const stud of students) {
                let upperCaseKeysStudentData = Object.keys(stud).reduce(
                  (result, key) => {
                    result[key.toUpperCase()] = stud[key];
                    return result;
                  },
                  {}
                );

                // console.log(upperCaseKeysStudentData);

                var studentDocRef = fb.db
                  .collection("schools")
                  .doc(String(esis_id))
                  .collection("students-2024")
                  .doc(String(upperCaseKeysStudentData.PERSON_ID));

                upperCaseKeysStudentData["classGroup-2024"] = programDocRef;
                upperCaseKeysStudentData["classGroupName"] =
                  upperCaseKeysCLASS_GROUP.STUDENT_GROUP_NAME;
                upperCaseKeysStudentData["classGroupName-2024"] =
                  upperCaseKeysCLASS_GROUP.STUDENT_GROUP_NAME;
                upperCaseKeysStudentData["PROGRAM_STAGE_ID"] = Number(
                  upperCaseKeysCLASS_GROUP.PROGRAM_STAGE_ID
                );
                upperCaseKeysStudentData["STUDENT_GROUP_NAME"] =
                  upperCaseKeysCLASS_GROUP.STUDENT_GROUP_NAME;

                //PROGRAM_STAGE_ID

                // stat.PROGRAM_STAGE_ID = stat.PROGRAM_STAGE_ID?stat.PROGRAM_STAGE_ID:null
                // stat.STUDENT_GROUP_NAME = stat.STUDENT_GROUP_NAME?stat.STUDENT_GROUP_NAME:null

                upperCaseKeysStudentData.created = new Date();

                upperCaseKeysStudentData.deleted = false;
                //departmentIndex
                upperCaseKeysStudentData.firstName =
                  upperCaseKeysStudentData.FIRST_NAME;
                upperCaseKeysStudentData.lastName =
                  upperCaseKeysStudentData.LAST_NAME;
                upperCaseKeysStudentData.readfrom_esis = true;
                upperCaseKeysStudentData.readfrom_esis2 = true;
                upperCaseKeysStudentData.role = "student";
                upperCaseKeysStudentData.roleName = "Сурагч";

                batch.set(studentDocRef, upperCaseKeysStudentData, {
                  merge: true,
                });
              }
              // console.log(students, cg.name);
            }

            batch.commit().then(() => {
              console.log("Done!");
              // this.loadingSaving = false
              this.loading = false;
            });
            var counter = 0;
            for (const ss of this.gClassGroups) {
              ss.index = ++counter;
              console.log(ss, "aa");
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.$swal.fire("Байгууллагын код олдсонгүй эсвэл гэрээт биш байгууллага байна!")
        });
    },
    async _retrieveClassGroupStudents(studentGroupId) {
      var responce = await axios.get(
        "https://dep.edu.mn/api/contracted-orgs/group_members",
        {
          params: {
            student_group_id: studentGroupId,
          },
          headers: {
            ClientId: this.$store.state.middleSystemInfo.ClientId,
            ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
          },
        }
      );
      console.log(responce.data.items);
      return responce.data.items;
    },
    async _retrieveStudents(studentGroup) {
      await axios
        .get("https://dep.edu.mn/api/contracted-orgs/group_members", {
          params: {
            student_group_id: studentGroup.student_group_id,
          },
          headers: {
            ClientId: this.$store.state.middleSystemInfo.ClientId,
            ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
          },
        })
        .then((response) => {
          console.log("Response:", response.data);
          var ppp =
            "/departments-" +
            this.userData.school.currentYear +
            "/department-" +
            studentGroup.academic_level +
            "/programs/" +
            studentGroup.student_group_id;
          console.log("ppp", ppp);
          if (response.data && response.data.items) {
            console.log(
              "responce data",
              response.data.items,
              response.data.items.length
            );

            var counter = 0;

            for (const ss of response.data.items) {
              ss.index = ++counter;
              //console.log(ss);
              ///gClassGroups/AFXAMMKndlB137SWH9P4/departments-2024/department-10/programs/100004206612045
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
};
</script>
<style>
.centered-input {
  text-align: center;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the width */
  margin: 20px auto 10px auto;
}
.swal2-input[type="number"] {
  max-width: 20em;
}
.modal-title {
  text-align: center; /* Center the title text (optional) */
}
</style>
